html {
  scroll-behavior: smooth;
}

html,
body,
#root {
  width: 100%;
}

*,
body,
button,
input,
optgroup,
select,
textarea {
  margin: 0;
  font-family: Open Sans, -apple-system, BlinkMacSystemFont, 'Segoe UI',
    'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', 'Bree Serif', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
